import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withKeys as _withKeys, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "logo flex",
  style: {"flex-direction":"column"}
}
const _hoisted_2 = {
  class: "logo flex",
  style: {"flex-direction":"column"}
}
const _hoisted_3 = {
  class: "logo flex",
  style: {"flex-direction":"column"}
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "msg" }
const _hoisted_6 = { style: {"font-weight":"700"} }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "msg" }
const _hoisted_9 = { style: {"font-weight":"700"} }

import { computed, nextTick, onMounted, ref, watch } from 'vue';
import * as api from './api-stepan';
import { toast } from  '@/helpers/error.helper';
import { Button, Spin } from '@/shared/ui';
// @ts-ignore */
import { VMarkdownView } from 'vue3-markdown'
import { useRoute, useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'TestPageStepan',
  setup(__props) {

const text = ref('')
const runExitId = ref('')
const loading = ref(false)
const threads = ref<any>([])
const messages = ref<any>([])
const currentBarcode = ref('')
const newBarcode = ref('')
const newType = ref(null)
const newName = ref('')
const newGender = ref(null)
const autoThread = ref(false)
const threadsOptions = computed(()=>{
  const result = threads.value.map((thread: any) => ({
    label: thread.request_id,
    value: thread.request_id
  }))
  return result
})
const lastAssistant = ref(null)
const isAutoThread = computed(() => !!currentThread.value?.role)
const currentThread = computed(() => threads.value.find((thread: any) => thread.request_id === currentBarcode.value))

async function send(){
  if(loading.value || !text.value) return
  const textPayload = text.value
  text.value = ''
  try {
    loading.value = true
    const result = isAutoThread.value ? await api.createAutoMessage(textPayload, currentBarcode.value) : await api.createMessage(textPayload, currentBarcode.value)
    if(result.success){
      toast({ msg: 'Message sent', type: 'success' })
      fetchThread()
      text.value = ''
    } else {
      toast({ msg: result })
    }
  } catch(error){
    toast({ msg: error })
  }
} 

async function checkRun(){
  loading.value = true
  console.log(currentThread.value)
  const result = await api.checkRun(runExitId.value, currentThread.value?.ext_id)
  console.log(result)
  // if(result.success){
  //   toast({ msg: 'Checking...', type: 'success' })
  //   fetchThread()
  //   text.value = ''
  // } else {
  //   toast({ msg: result })
  // }
}

async function generate(){
  loading.value = true
  const result = await api.generateAutoMessage(currentBarcode.value)
  if(result.success){
    toast({ msg: 'Generating...', type: 'success' })
    fetchThread()
    text.value = ''
  } else {
    toast({ msg: result })
  }
}
async function nextSample(){
  loading.value = true
  const result = await api.nextSampleAutoMessage(currentBarcode.value)
  if(result.success){
    toast({ msg: 'Generating...', type: 'success' })
    fetchThread()
    text.value = ''
  } else {
    toast({ msg: result })
  }
}
onMounted(async () => {
  await fetchThreads()
    currentBarcode.value = threads.value[0].request_id
})

async function createBarcode(){
  if(newBarcode.value && newType.value && newName.value && newGender.value && !loading.value){
    loading.value = true
    try {
      if(autoThread.value){
        await api.createAutoThread({
          test_type: newType.value,
          barcode: newBarcode.value,
          patient_name: newName.value,
          patient_gender: newGender.value
        })
      } else {
        await api.createThread({
          test_type: newType.value,
          barcode: newBarcode.value,
          patient_name: newName.value,
          patient_gender: newGender.value
        })
      }
      fetchThreads()
      toast({ msg: 'New thread created', type: 'success' })
      currentBarcode.value = newBarcode.value
      newType.value = null
      newGender.value = null
      newName.value = ''
      newBarcode.value = ''
      } catch (error) {
        toast({ msg: error })
      } finally {
        loading.value = false
    }
  }
}

async function fetchThreads(){
  loading.value = true
  threads.value = await api.fetchThreads()
  loading.value = false
}
const interval = ref<any>(null)
function fetchThread(){
  interval.value = setInterval(async () => {
    loading.value = true
    messages.value = await api.fetchThread(currentBarcode.value)
    await nextTick()
    wrapper.value.scrollTop = wrapper.value.scrollHeight;
    if(currentThread.value.role) {
      const lastAssistantRaw = messages.value[0].id
      if(lastAssistantRaw !== lastAssistant.value) {
        clearInterval(interval.value)
        lastAssistant.value = messages.value[0].id
        await nextTick()
        loading.value = false
      }
    } else {
      const lastAssistantRaw = messages.value.find((msg: any) => msg.role === 'assistant')?.id
      if(lastAssistantRaw !== lastAssistant.value) {
        clearInterval(interval.value)
        lastAssistant.value = messages.value.find((msg: any) => msg.role === 'assistant')?.id
        await nextTick()
        loading.value = false
      }
    }
    messages.value.reverse()
  }, 1000)
}

const route = useRoute()
const router = useRouter()
onMounted(async () => {
  await fetchThreads()
  if(route.query?.barcode){
    currentBarcode.value = route.query.barcode as string
  } else {
    currentBarcode.value = threads.value[0].request_id
  }
})


const wrapper = ref()
async function generateAnalysis(){
  loading.value = true
  await api.generateAnalysis(currentBarcode.value)
  loading.value = false
}

const currentAnalysis = ref<any>(null)
const isShowAnalysis = ref(false)
async function showAnalysis(){
  const response =  await api.showAnalysis(currentBarcode.value)
  currentAnalysis.value = response.data.reverse()
  isShowAnalysis.value = true
}

watch(()=>currentBarcode.value, async (newThread) => {
  clearInterval(interval.value)
  lastAssistant.value = null
  loading.value = true
  router.push({ query: { barcode: newThread } })
  if (newThread) {
    fetchThread()
  } 
})

return (_ctx: any,_cache: any) => {
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_layout, {
      class: "main",
      style: {"min-height":"100vh"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_sider, { width: "400" }, {
          default: _withCtx(() => [
            _cache[16] || (_cache[16] = _createElementVNode("div", {
              class: "logo flex",
              style: {"padding":"10px"}
            }, "Emma Testing Page", -1)),
            _createElementVNode("div", _hoisted_1, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { style: {"margin":"10px"} }, "Choose thread", -1)),
              _createVNode(_component_a_select, {
                style: {"width":"100%"},
                options: threadsOptions.value,
                value: currentBarcode.value,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((currentBarcode).value = $event))
              }, null, 8, ["options", "value"])
            ]),
            _createElementVNode("div", _hoisted_2, [
              _cache[12] || (_cache[12] = _createElementVNode("div", { style: {"margin":"10px"} }, "New thread", -1)),
              _createVNode(_component_a_input, {
                style: {"width":"100%"},
                value: newBarcode.value,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((newBarcode).value = $event)),
                placeholder: 'Barcode'
              }, null, 8, ["value"]),
              _createVNode(_component_a_select, {
                style: {"width":"100%"},
                options: [
            { label: 'Male', value: 'male' },
            { label: 'Female', value: 'female' },
          ],
                allowClear: "",
                placeholder: 'Gender',
                value: newGender.value,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((newGender).value = $event))
              }, null, 8, ["value"]),
              _createVNode(_component_a_input, {
                style: {"width":"100%"},
                value: newName.value,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((newName).value = $event)),
                placeholder: 'Name'
              }, null, 8, ["value"]),
              _createVNode(_component_a_select, {
                style: {"width":"100%"},
                options: [
            { label: 'Lactulose', value: 'Lactulose' },
            { label: 'Glucose', value: 'Glucose' },
            { label: 'Lactose', value: 'Lactose' },
            { label: 'Fructose', value: 'Fructose' },
          ],
                allowClear: "",
                placeholder: 'Test type',
                value: newType.value,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((newType).value = $event))
              }, null, 8, ["value"]),
              _createElementVNode("label", null, [
                _cache[10] || (_cache[10] = _createTextVNode(" Auto-thread ")),
                _createVNode(_component_a_checkbox, {
                  style: {"margin":"10px"},
                  checked: autoThread.value,
                  "onUpdate:checked": _cache[5] || (_cache[5] = ($event: any) => ((autoThread).value = $event))
                }, null, 8, ["checked"])
              ]),
              _createVNode(_unref(Button), {
                onClick: createBarcode,
                disabled: !newBarcode.value || !newType.value || !newName.value || !newGender.value || loading.value,
                style: {"margin":"20px"}
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode("Create new thread")
                ])),
                _: 1
              }, 8, ["disabled"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _cache[15] || (_cache[15] = _createElementVNode("div", { style: {"margin":"10px"} }, "Runs", -1)),
              _createVNode(_unref(Button), {
                style: {"margin-bottom":"20px"},
                onClick: showAnalysis
              }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode(" Start Run ")
                ])),
                _: 1
              }),
              _createVNode(_component_a_input, {
                value: currentThread.value?.ext_id,
                style: {"padding":"5px"}
              }, null, 8, ["value"]),
              _createVNode(_component_a_input, {
                value: runExitId.value,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((runExitId).value = $event)),
                style: {"padding":"5px"}
              }, null, 8, ["value"]),
              _createVNode(_unref(Button), {
                style: {"margin-bottom":"20px"},
                onClick: checkRun
              }, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode(" Check Run ")
                ])),
                _: 1
              })
            ]),
            (loading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_unref(Spin), { size: '80px' })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_a_layout, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_layout_content, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "wrapper",
                  ref_key: "wrapper",
                  ref: wrapper
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(messages.value, (msg) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("div", null, [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(msg.role), 1),
                        _cache[17] || (_cache[17] = _createTextVNode()),
                        _createElementVNode("span", null, "(" + _toDisplayString(msg.created_at) + ")", 1)
                      ]),
                      _createVNode(_unref(VMarkdownView), {
                        content: msg.content
                      }, null, 8, ["content"])
                    ]))
                  }), 256))
                ], 512)
              ]),
              _: 1
            }),
            _createVNode(_component_a_layout_footer, { style: {"text-align":"center"} }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_a_input, {
                    placeholder: "Your message",
                    value: text.value,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((text).value = $event)),
                    onKeyup: _withKeys(send, ["enter"])
                  }, null, 8, ["value"]),
                  _createVNode(_unref(Button), {
                    style: {"margin":"10px"},
                    onClick: send,
                    disabled: loading.value || !text.value
                  }, {
                    default: _withCtx(() => _cache[18] || (_cache[18] = [
                      _createTextVNode("Send message")
                    ])),
                    _: 1
                  }, 8, ["disabled"]),
                  (isAutoThread.value)
                    ? (_openBlock(), _createBlock(_unref(Button), {
                        key: 0,
                        style: {"margin":"10px"},
                        onClick: generate,
                        disabled: loading.value
                      }, {
                        default: _withCtx(() => _cache[19] || (_cache[19] = [
                          _createTextVNode("Generate")
                        ])),
                        _: 1
                      }, 8, ["disabled"]))
                    : _createCommentVNode("", true),
                  (isAutoThread.value)
                    ? (_openBlock(), _createBlock(_unref(Button), {
                        key: 1,
                        style: {"margin":"10px"},
                        onClick: nextSample,
                        disabled: loading.value
                      }, {
                        default: _withCtx(() => _cache[20] || (_cache[20] = [
                          _createTextVNode("Next Sample")
                        ])),
                        _: 1
                      }, 8, ["disabled"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      visible: isShowAnalysis.value,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((isShowAnalysis).value = $event)),
      title: "Analysis",
      width: "100%",
      "wrap-class-name": "full-modal"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "wrapper",
          ref_key: "wrapper",
          ref: wrapper
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentAnalysis.value, (msg) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(msg.role), 1),
                _cache[21] || (_cache[21] = _createTextVNode()),
                _createElementVNode("span", null, "(" + _toDisplayString(msg.created_at) + ")", 1)
              ]),
              _createVNode(_unref(VMarkdownView), {
                content: msg.content?.[0]?.text?.value
              }, null, 8, ["content"])
            ]))
          }), 256))
        ], 512)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})